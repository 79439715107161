import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { Container, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import Worklist from '../components/workList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const WorksPage = ({
  data: {
    allMarkdownRemark: {
      edges
    }
  },
  pageContext,
}) => {
  const pagination =
    new Array(pageContext.numPages).fill(0).map((_, i) => ({
      index: i + 1,
      active: i + 1 === pageContext.currentPage,
    }))


  return (
    <Layout>
      <SEO title="My Works" keywords={[`gatsby`, `application`, `react`]} />
      <Container>
        <div className="text-center pt-5 pb-5">
          <h1>Projects and contributions</h1>
        </div>

        <Worklist edges={edges} />

        {pagination.length > 1 && (
          <Pagination listClassName="justify-content-center">
            {pagination.map(({ index, active }) => (
              <PaginationItem active={active} key={index}>
                <PaginationLink href={`/works${index > 1 ? `/${index}` : '' }`}>
                  {index}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem disabled={pageContext.currentPage === pageContext.numPages}>
              <PaginationLink href={`/works/${pageContext.currentPage + 1}`}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
            </PaginationItem>
          </Pagination>
        )}
      </Container>
    </Layout>
  )
}

export default WorksPage

export const pageQuery = graphql`
  query queryPaginatedWorks($skip: Int, $limit: Int) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___startdate] }
      filter: { fields: { collection: { eq: "works" } }, frontmatter: { active: { eq: true } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            collection
          }
          frontmatter {
            path
            title
            summary
            startdate
            technologies
            active
            cover {
              childImageSharp{
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
